import clsx from 'clsx';
import { ComponentProps } from 'react';
import { inputIconButton } from './input-icon-button.css';

type InputIconButtonProps = ComponentProps<'button'>;

export const InputIconButton = ({
  children,
  ref,
  className,
  ...props
}: InputIconButtonProps) => {
  return (
    <button
      type={'button'}
      className={clsx(inputIconButton, className)}
      {...props}
    >
      {children}
    </button>
  );
};
